// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import Spinner from 'layout/Spinner'
import MarkdownBody from 'elements/MarkdownBody'
import SignUp from 'components/SignUp'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
// HOOKS
import { useExternalContent } from '../hooks/useExternalContent'

// Hero component
const Hero = ({ content }) => {
  const compContent = useExternalContent(content)

  if (compContent) {
    const { order = 0, section, section: {inverted}, title, subtitle, bodyMd, signup } = content

    const body = bodyMd && <MarkdownBody markdown={bodyMd} inverted={inverted} />
    const copy = <>
      { title && <h1 className='display-3'>{title}</h1> }
      { subtitle && <h2 className='display-6'>{subtitle}</h2> }
    </>
    const subscribe = signup && <SignUp content={signup} />

    let ordering = []
    switch(order){
      // Copy left, body right, subscribe bottom
      case 0:
        ordering = [copy, body, subscribe]
        break
      // Body left, copy right, subscribe bottom
      case 1:
        ordering = [body, copy, subscribe]
        break
      default:
        ordering = [copy, body, subscribe]
        break
    }

    return (
      <Section content={section}>
        <Container fluid>
          <Row className='pt-4'>
            <Col className='my-md-auto mb-xs-4' md={6} xs={12}>
              {ordering[0]}
            </Col>
            <Col className='my-auto' md={6} xs={12}>
              {ordering[1]}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {ordering[2]}
            </Col>
          </Row>
        </Container>
      </Section>
    )
  } else { return( <Container fluid><Spinner/></Container> ) }
}

export default Hero
