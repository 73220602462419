// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import Spinner from 'layout/Spinner'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
// APIs
import { contentPicker } from 'apis/FetchContent'
// HOOKS
import { useExternalContent } from '../hooks/useExternalContent'

// Banner component
const Banner = ({ content }) => {
  const compContent = useExternalContent(content, loadContent)
  // const compContent = false

  // console.log(compContent)
  if (compContent) {
    // console.log(compContent)
    const { section, section: {inverted}, title, subtitle, bodyMd, button, imageUrl, videoUrl } = compContent
    return (
      <Section content={section}>
        <Container fluid>
          <Row>
            <Col className='pb-4' md={6} xs={12}>
              <Row>
                <Col className='text-left mb-4' xs={12}>
                  { title && <h1 className='display-3'>{title}</h1> }
                  { subtitle && <h2 className='display-6'>{subtitle}</h2> }
                </Col>
                <Col className='mb-4' xs={12}>
                  { bodyMd && <MarkdownBody markdown={bodyMd} inverted={inverted} /> }
                </Col>
                { button &&
                <Col className='text-center' xs={12}>
                  <Button className='btn-info btn-lg btn-block' href={button.link}>
                    {button.text}
                  </Button>
                </Col>
                }
              </Row>
            </Col>
            <Col className='d-flex align-items-center justify-content-center mb-4' md={6} xs={12}>
              {imageUrl && 
              <img className='rounded mx-auto d-block' src={imageUrl} alt='banner' style={{ maxHeight: '300px' }} />
              }
              {videoUrl && 
              <video className='rounded' styles='max-height: 300px' playsInline autoPlay muted loop controls>
                <source src={videoUrl} type='video/mp4'/>
              </video>
              }
            </Col>
          </Row>
        </Container>
      </Section>
    )
  } else { return( <Container fluid><Spinner/></Container> ) }

}


// Load content
const loadContent = async (c) => {
  // Select a group of content to display by random index
  if (c) {
    // console.log(c)
    const content = c.isExtContent ? c.banner : c
    const { section, title, titles, subtitle, subtitles, bodyMd, bodyMds, button, buttons, imageUrl, imageUrls, videoUrl, videoUrls } = content
    // console.log({titles, subtitles, bodyMds})
    const randomContent = {
      section,
      title: contentPicker(titles, title),
      subtitle: contentPicker(subtitles, subtitle),
      bodyMd: contentPicker(bodyMds, bodyMd),
      button: contentPicker(buttons, button),
      imageUrl: contentPicker(imageUrls, imageUrl),
      videoUrl: contentPicker(videoUrls, videoUrl)
    }
    return await randomContent
  } else {
    return null
  }
}

export default Banner
